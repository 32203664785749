import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthToken from '../hooks/useAuthToken';
import LoadingView from 'views/LoadingView';

interface Auth0ProtectedRouteProps {
  children: JSX.Element;
}

export default function Auth0ProtectedRoute(props: Auth0ProtectedRouteProps): JSX.Element {
  const { isAuthenticated, isLoading } = useAuth0();
  const hasToken = useAuthToken();

  if (!isAuthenticated && !isLoading) {
    return <Navigate to="/login" replace />;
  }

  if (!isAuthenticated || !hasToken) {
    return <LoadingView />;
  }

  return props.children;
}
