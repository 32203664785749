import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { fetchAccounts } from '../reducers/snowflakeAccountsSlice';
import { useAppDispatch } from 'app/hooks';
import { XMarkIcon } from '@heroicons/react/20/solid';
import LoadingView from 'views/LoadingView';

interface SnowflakeAccountLoginInnerViewProps {
  authType: 'auth0' | 'platform';
}

export default function SnowflakeAccountLoginInnerView(props: SnowflakeAccountLoginInnerViewProps): JSX.Element {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const integrationId = searchParams.get('integrationId');
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const warehouse = searchParams.get('warehouse');
  const role = searchParams.get('role');
  const accountId = searchParams.get('account_id');
  const redirectUrl = searchParams.get('redirect_url');

  const [toLoad, setToLoad] = useState<boolean>(true);
  const [finished, setFinished] = useState<boolean>(false);
  // const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (integrationId != null && code != null && state != null) {
      if (!toLoad) return;
      // e.preventDefault();
      setToLoad(false);
      let url = `/api/v1/snowflake/account`;
      let method = 'post';
      if (accountId !== null) {
        url = `/api/v1/snowflake/account/${accountId}`;
        method = 'put';
      }
      const redirectParams = new URLSearchParams([['auth_type', props.authType]]);
      axios
        .request({
          url,
          method,
          data: {
            type: 'oauth',
            code,
            warehouse,
            role,
            oauth_integration_id: integrationId,
            redirect_url: `${window.location.origin}/snowflake-accounts/auth?${redirectParams.toString()}`,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(err.response.data);
          setShowError(true);
        })
        .finally(() => {
          setFinished(true);
          dispatch(fetchAccounts()).catch((err) => err);
        });
    } else {
      if (!finished && toLoad) {
        auth.signinRedirect().catch((err) => err);
      }
    }
  }, [auth]);
  if (finished && !showError && redirectUrl !== null) {
    window.location.href = redirectUrl;
    return <></>;
  }
  if (finished && !showError) {
    if (props.authType === 'platform') {
      const createUrl =
        process.env.REACT_APP_ENV === 'az'
          ? 'https://create.az.dataops.live/dataops-oauth-callback'
          : process.env.REACT_APP_ENV === 'production'
          ? 'https://create.dataops.live/dataops-oauth-callback'
          : 'https://create.qa.dataops.live/dataops-oauth-callback';
      return <Navigate to={createUrl} />;
    } else {
      return <Navigate to="/snowflake-accounts" />;
    }
  }

  return (
    <>
      {showError && (
        <div className="h-full min-w-0 flex-1">
          <>
            <div className="fixed top-[7.2rem] left-16 right-0 z-10 rounded-md bg-red-50 p-4">
              <div className="flex w-full">
                <div className="ml-3 w-full">
                  <div className="flex">
                    <h3 className="flex-grow text-sm font-medium text-red-800">Update error</h3>
                    <button
                      type="button"
                      onClick={() => setShowError(false)}
                      className="h-5 w-5 mr-4 inline-flex items-center rounded-full text-red-800 hover:bg-red-100"
                    >
                      <XMarkIcon className="w-full" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="w-full text-sm text-red-800">{errorMessage}</div>
                </div>
              </div>
            </div>
            <div className="mt-[7.5rem]"></div>
          </>
        </div>
      )}
      {!showError && <LoadingView />}
    </>
  );
}
