import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

export default function Auth0CallbackView(): JSX.Element {
  const { isAuthenticated, error, logout } = useAuth0();

  if (error !== undefined) {
    return (
      <div className="w-full h-full pt-8 flex flex-col items-center space-y-8">
        <div className="text-2xl text-gray-800">Access denied</div>
        <div className="w-[30rem] text-center text-base text-gray-700">{error.message}</div>
        <div>
          <button
            className="relative flex mx-[auto] justify-center rounded-md border border-transparent bg-dataops-primary-blue py-2 px-4 text-sm font-medium text-white hover:bg-cyan-700"
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } }).catch((e) => e);
            }}
          >
            Back to login
          </button>
        </div>
      </div>
    );
  } else if (!isAuthenticated) {
    return (
      <div className="h-full w-full flex flex-col items-center">
        <div
          className="animate-spin inline-block w-32 h-32 mt-16 border-[3px] border-current border-t-transparent text-cyan-800 rounded-full"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" replace />;
  }
}
