import React from 'react';
import { useSearchParams, useLocation, Navigate } from 'react-router-dom';
import LoadingView from './LoadingView';

export default function SharedAuthCallbackView(): JSX.Element {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const authType = searchParams.get('auth_type');

  if (authType === 'auth0') {
    return <Navigate to={`/auth0${location.pathname}${location.search}`} />;
  }

  if (authType === 'platform') {
    return <Navigate to={`/platform-auth${location.pathname}${location.search}`} />;
  }

  return <LoadingView />;
}
