import React from 'react';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './react-oauth2-code-pkce/AuthContext';
import type { TAuthConfig, TRefreshTokenExpiredEvent } from './react-oauth2-code-pkce';
import { getConfig } from './config/config-helper';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import './index.css';
import LoginView from 'views/LoginView';
import Auth0CallbackView from 'views/Auth0CallbackView';
import PlatformAuthCallbackView from 'views/PlatformAuthCallbackView';
import SharedAuthCallbackView from 'views/SharedAuthCallbackView';
import AdminView from 'views/AdminView';
import UserManagementView from 'views/UserManagementView';
import UserManagementUsersTab from 'views/UserManagementUsersTab';
import UserManagementGroupsTab from 'views/UserManagementGroupsTab';
import UserManagementRolesTab from 'views/UserManagementRolesTab';
import ErrorView from 'views/ErrorView';
import NotFoundView from 'views/NotFoundView';
import PublicRoute from 'features/authentication/components/PublicRoute';
import Auth0ProtectedRoute from 'features/authentication/components/Auth0ProtectedRoute';
import PlatformAuthProtectedRoute from 'features/authentication/components/PlatformAuthProtectedRoute';
import SnowflakeAccountsView from 'views/SnowflakeAccountsView';
import OauthIntegrationsView from 'views/OauthIntegrationsView';
import SnowflakeAccountLoginView from 'views/SnowflakeAccountLoginView';
import LastVisitedUrlTracker from 'components/LastVisitedUrlTracker';

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

const { dataopsOAuthClient } = getConfig();
const { authorizationEndpoint, tokenEndpoint, redirectUri, scope, decodeToken, redirectCallbackPath, autoLogin } =
  dataopsOAuthClient;

const authConfig: TAuthConfig = {
  clientId: dataopsOAuthClient.clientId,
  authorizationEndpoint,
  tokenEndpoint,
  redirectUri,
  scope,
  decodeToken,
  redirectCallbackPath,
  autoLogin,
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => {
    window.confirm('Session expired. Refresh page to continue using the site?') && event.login();
  },
};

const router = createBrowserRouter([
  {
    path: '/snowflake-accounts/auth',
    element: <SharedAuthCallbackView />,
  },
  {
    path: '/snowflake-accounts/integrations/:id/auth',
    element: <SharedAuthCallbackView />,
  },
  {
    path: '/platform-auth',
    element: (
      <AuthProvider authConfig={authConfig}>
        <LastVisitedUrlTracker />
        <PlatformAuthProtectedRoute>
          <App />
        </PlatformAuthProtectedRoute>
      </AuthProvider>
    ),
    children: [
      { path: '/platform-auth/snowflake-accounts/auth', element: <SnowflakeAccountLoginView authType="platform" /> },
      {
        path: '/platform-auth/snowflake-accounts/integrations/:id/auth',
        element: <SnowflakeAccountLoginView authType="platform" />,
      },
    ],
  },
  {
    path: '/dataops-oauth-callback',
    element: (
      <AuthProvider authConfig={authConfig}>
        <PlatformAuthCallbackView />
      </AuthProvider>
    ),
  },
  {
    path: '/',
    element: (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_ADMIN_UI_CLIENT_ID}
        skipRedirectCallback={window.location.pathname === '/snowflake-accounts/auth'}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: `${window.location.origin}/react-callback`,
          audience: 'https://admin.dataops.live/customer_admin/',
        }}
      >
        <App />
      </Auth0Provider>
    ),
    errorElement: <ErrorView />,
    children: [
      {
        path: '/',
        element: (
          <Auth0ProtectedRoute>
            <AdminView />
          </Auth0ProtectedRoute>
        ),
        children: [
          {
            path: '/',
            element: <Navigate replace to="/snowflake-accounts" />,
          },
          {
            path: '/snowflake-accounts',
            element: <SnowflakeAccountsView />,
          },
          {
            path: '/snowflake-accounts/integrations',
            element: <OauthIntegrationsView />,
          },
          {
            path: '/auth0/snowflake-accounts/auth',
            element: <SnowflakeAccountLoginView authType="auth0" />,
          },
          {
            path: '/auth0/snowflake-accounts/integrations/:id/auth',
            element: <SnowflakeAccountLoginView authType="auth0" />,
          },
          {
            path: '/user-management',
            element: <UserManagementView />,
            children: [
              {
                path: '/user-management',
                element: <UserManagementUsersTab />,
              },
              {
                path: '/user-management/groups',
                element: <UserManagementGroupsTab />,
              },
              {
                path: '/user-management/roles',
                element: <UserManagementRolesTab />,
              },
            ],
          },
        ],
      },
      {
        path: '/login',
        element: (
          <PublicRoute>
            <LoginView />
          </PublicRoute>
        ),
      },
      {
        path: '/react-callback',
        element: <Auth0CallbackView />,
      },
      {
        path: '*',
        element: <NotFoundView />,
      },
    ],
  },
]);

const container = document.getElementById('root');

if (container != null) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
