import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce/AuthContext';
import type { IAuthContext } from 'react-oauth2-code-pkce';
import LoadingView from 'views/LoadingView';

interface PlatformAuthProtectedRouteProps {
  children: JSX.Element;
}

export default function PlatformAuthProtectedRoute(props: PlatformAuthProtectedRouteProps): JSX.Element {
  const { token, login, loginInProgress } = useContext<IAuthContext>(AuthContext);
  const tokenRef = useRef(token);
  const [axiosBearerSet, setAxiosBearerSet] = useState(false);

  function loginWithRetry(retrigger: boolean): void {
    // Already logged in, nothing to do
    if (tokenRef.current !== '') {
      return;
    }

    if (!loginInProgress || retrigger) {
      login();
    }

    setTimeout(() => {
      loginWithRetry(true);
    }, 5000);
  }

  useEffect(() => {
    tokenRef.current = token;
    loginWithRetry(false);
  }, [token]);

  useEffect(() => {
    if (token !== '') {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      setAxiosBearerSet(true);
    }
  }, [token]);

  if (token === '' || !axiosBearerSet) {
    return <LoadingView />;
  }

  return props.children;
}
