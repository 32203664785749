import qa from './qa.json';
import dev from './dev.json';
import prod from './prod.json';
import az from './az.json';

interface IConfig {
  dataopsOAuthClient: {
    clientId: string;
    authorizationEndpoint: string;
    tokenEndpoint: string;
    redirectUri: string;
    scope: string;
    decodeToken: boolean;
    redirectCallbackPath: string;
    autoLogin: boolean;
  };
  dataopsliveBaseUrl: string;
  dataopsGraphQlApiEndpoint: string;
}

export function getConfig(): IConfig {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return dev;
    case 'qa':
      return qa;
    case 'production':
      return prod;
    case 'az':
      return az;
    default:
      return dev;
  }
}
